import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import ROUTES from './routes';
import { ProtectedRoute } from 'feature/auth';
import { LoginPage } from 'pages/Login';
import { Main } from 'pages/Main';
import { Menu } from 'widget/menu/Menu';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        path: '/',
        element: <LoginPage />,
      },
      {
        path: ROUTES.APP,
        element: (
          <ProtectedRoute>
            <Outlet />
            <Menu />
          </ProtectedRoute>
        ),
        children: [
          {
            path: '',
            element: <Main />,
          },
          {
            path: '/APP/test',
            element: <>go app</>,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.LOGIN, // Страница входа
    element: <LoginPage />,
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.LOGIN} />, // Перенаправляем на логин для несуществующих маршрутов
  },
]);
