import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import ROUTES from 'app/router/routes';
import { useAuth } from '../model/authProvoider/useAuth';
import { use3dModelContext } from 'feature/3d';

export const Login = () => {
  const { isReady } = useAuth();
  const { isLoading } = use3dModelContext();
  const navigate = useNavigate();
  console.log(isLoading);
  useEffect(() => {
    if (isReady && !isLoading) {
      // Если инъекция готова и пользователь авторизован, перенаправляем на основной роут
      navigate(ROUTES.APP, { replace: true });
    }
  }, [isReady, isLoading]);

  return <>Open app in TG</>;
};
