import React, { createContext, useContext, useEffect, useState } from 'react';

import { TelegramUser, useTelegramAuth } from 'feature/auth';

import { AuthContextProps } from './types';

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isReady, user: telegramUser } = useTelegramAuth(); // Используем хук

  const [user, setUser] = useState<TelegramUser | null>(null);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

  // Эффект для синхронизации состояния пользователя
  useEffect(() => {
    if (telegramUser) {
      setUser(telegramUser);
      setAuthenticated(true); // Если пользователь авторизован через Telegram
    } else {
      setUser(null);
      setAuthenticated(false);
    }
  }, [telegramUser]);

  const login = () => {
    setAuthenticated(true);
  };

  const logout = () => {
    setUser(null);
    setAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ user, isReady, isAuthenticated, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
