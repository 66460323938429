import { useState, useEffect } from 'react';
import { TelegramUser } from './types';

// Хук для авторизации пользователя в Telegram Web App
export const useTelegramAuth = () => {
  const [user, setUser] = useState<TelegramUser | null>(null);
  const [initData, setInitData] = useState<string | null>(null);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    if (typeof window.Telegram !== 'undefined') {
      const tg = window.Telegram.WebApp;

      tg.ready();

      const userData = tg.initDataUnsafe?.user;
      if (userData) {
        // Если пользовательские данные доступны, обновляем состояние
        setUser(userData);
        setInitData(tg.initData);
        setReady(true);
      } else {
        // Если данные пользователя недоступны, устанавливаем isReady в false
        console.warn('User data not available');
        setReady(false);
      }
    } else {
      console.error('Telegram Web App is not available');
      setReady(false);
    }

    // Функция очистки (если нужно)
    return () => {
      setUser(null);
      setInitData(null);
      setReady(false);
    };
  }, []);

  return { user, initData, isReady };
};
