import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useTelegramAuth } from '../telegramAuth/TelegramAuth';
import { useAuth } from '../authProvoider/useAuth';

interface ProtectedRouteProps {
  children: ReactNode; // Определяем тип для children
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isReady } = useAuth();
  // Если пользователь не авторизован, перенаправляем на страницу входа
  if (!isReady) {
    return <Navigate to='/login' replace />;
  }

  // Если пользователь авторизован, возвращаем защищенные дочерние компоненты
  return <>{children}</>;
};
