import { RobotScene } from 'feature/3d';
import type { FC } from 'react';

export const Main: FC = () => {
  return (
    <div>
      <RobotScene />
    </div>
  );
};
