import { Model3dProvider } from 'feature/3d';
import { AuthProvider } from 'feature/auth';
import React, { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

interface AppProvidersProps {
  children: ReactNode;
}

export const AppProviders = ({ children }: AppProvidersProps) => {
  return (
    <ErrorBoundary
      fallback={<div className='boundary'>Something were wrong</div>}
    >
      <Model3dProvider>
        <AuthProvider>{children}</AuthProvider>
      </Model3dProvider>
    </ErrorBoundary>
  );
};
