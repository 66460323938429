import { useNavigate } from 'react-router-dom';

export const Menu = () => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate('/APP')}
      style={{ position: 'absolute', bottom: 0, width: 100, color: 'red' }}
    >
      Main
    </div>
  );
};
